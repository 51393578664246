import showFirstTimeUserVideo from "./utils/showFirstTimeUserVideo";
import getAuthClient from "./auth/getAuthClient";
import {ACCESS_TOKEN_KEY} from "./utils/constants";

let authClient = getAuthClient()
authClient.tokenManager.get(ACCESS_TOKEN_KEY).then(r => {
  // token not found or expired
  if (!r) {
    // get a new token and redirect back
    authClient.token.getWithRedirect()
  } else {
    window.addEventListener('DOMContentLoaded', (event) => {
      // remove hidden styles from body
      document.querySelector('body').removeAttribute('style')
      // we have an authenticated user, we play the video
      showFirstTimeUserVideo()
    })
  }
})
