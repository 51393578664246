export default function showFirstTimeUserVideo() {
  const cookieKey = 'initial-video-shown'
  const cookies = {}
  document.cookie.split(';').map(c => {
    const [key, value] = c.split('=')
    if (key && value) {
      cookies[key.trim()] = value.trim()
    }
  })

  if (!cookies[cookieKey]) {
    document.cookie = `${cookieKey}=1;max-age=${60 * 60 * 24 * 90};samesite=strict`
    playVideo()
  }
}

function playVideo() {
  const THRESHOLD = 0.75
  let observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > THRESHOLD) {
        entry.target.play()
        observer.unobserve(entry.target)
        window.gtag('event', 'video_auto_play') // GA tracking
      }
    })
  }, {threshold: THRESHOLD});

  let target = document.querySelector('.video video');
  observer.observe(target)
}
