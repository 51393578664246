import {OktaAuth} from "@okta/okta-auth-js";

export default function getAuthClient() {
  const config = {
    issuer: OKTA_DOMAIN_URL,
    clientId: OKTA_CLIENT_ID,
    redirectUri: OKTA_REDIRECT_URL,
    pkce: true,
    tokenManager: {
      storage: 'sessionStorage',
      expireEarlySeconds: 120,
    },
    cookies: {
      secure: false,
      sameSite: 'lax'
    }
  }

  return new OktaAuth(config)
}
